.content {
  max-width: 1000px;
  margin: auto;
}

.pageHeader {
  font-family: Montserrat;
  color: var(--primary);
  font-size: 60px;
  line-height: 63px;
  letter-spacing: -0.0333em;
  font-weight: 600;
  margin-bottom: 53px;
}

.sectionHeader {
  font-family: Montserrat;
  color: var(--text-color);
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.0222em;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 8px;
}

.paragraph,
.navigation {
  color: var(--text-color-transparent);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.75rem;
  margin-top: 0;
  margin-bottom: 28px;
}

.paragraphBold {
  color: var(--text-color);
}

.link {
  color: var(--primary);
}

.table {
  border-collapse: collapse;
  margin-bottom: 28px;
}

.table td {
  border: 1px solid var(--text-color);
  padding: 8px;
  color: var(--text-color-transparent);
  font-size: 16px;
  font-weight: 600;
}

.navigation {
  list-style: none;
  padding: 0;
}

.list li {
  padding-bottom: 12px;
}
