.header {
  height: 64px;
  background-color: var(--primary);
  flex-shrink: 0;
}

.headerLogo {
  width: 132px;
  margin: 12px;
}
