.footer {
  flex-shrink: 0;
  background-color: var(--primary);
  height: 220px;
  display: grid;
  grid-template-columns: 1fr 208px 42px 260px 260px 3fr;
  grid-template-rows: 60px 1fr 60px;
}

@media (max-width: 650px) {
  .footer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 460px;
    padding: 48px 12px;
  }

  .addressDetails {
    position: relative;
    top: -24px;
  }
}

.footerLogo {
  width: 208px;
  margin: 12px;
  grid-column: 2;
  grid-row: 1;
}

.footerHeader {
  grid-column: 4;
  grid-row: 1;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.0222em;
}

.footerText {
  color: var(--white);
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.addressDetails {
  grid-column: 4;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.footerLinks {
  grid-column: 5;
  grid-row: 2;
}

.footerLink {
  color: var(--link-color);
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  text-decoration: none;
  display: inline-block;
  position: relative;
  padding: 0.2em 0;
  overflow: hidden;
}

.footerLink::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(1);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--link-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.footerLink:hover::after,
.footerLink:focus::after {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.info {
  grid-column: 4 / 6;
  grid-row: 3;
}

.footerInfoText {
  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}
