body {
  margin: 0;
  font-family: Lato, Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary: #1a8fce;
  --white: #fff;
  --error: #ff665f;
  --warning: #ed6c02;
  --success: #26a69a;
  --link-color: rgba(255, 255, 255, 0.9);
  --text-color: #434346;
  --text-color-transparent: rgba(67, 67, 70, 0.75);
}

a {
  color: inherit;
}
